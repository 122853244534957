<template>
  <UiButton
    v-bind="$attrs"
    data-el="m2-add-to-cart-btn"
    data-test="m2-add-to-cart-button"
    :disabled="props.disabled"
    :variant="props.variant"
    @click="emit('click:add-to-cart')"
  >
    <slot>
      {{ t('ecom_to_cart') }}
    </slot>
  </UiButton>
</template>

<script lang="ts" setup>
import type { ButtonVariants } from '@ui/components/UiButton/UiButton.types'

interface AddToCartButton {
  disabled?: boolean
  variant?: ButtonVariants
}
const props = withDefaults(defineProps<AddToCartButton>(), {
  disabled: undefined,
  variant: 'primary',
})

const emit = defineEmits<{
  (e: 'click:add-to-cart'): void
}>()

const { t } = useI18n()
</script>
